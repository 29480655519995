import React, { useContext, useState } from 'react'
import { Link } from 'gatsby'
import styles from '../styles/MobileMenu.module.css'
import NavBar from './NavBar'
import { MobileMenuContext } from './MobileMenuButton'
import FSDialog from './FSDialog'
import ContactForm from './contactUs/ContactForm'

const MobileMenu = (): JSX.Element => {
  const [ openQuote, setOpenQuote ] = useState(false)
  const { closeMobileMenu } = useContext(MobileMenuContext)

  const handleOpenQuote = () => setOpenQuote(true)
  const handleCloseQuote = () => setOpenQuote(false)

  return (
    <div className={ styles.container }>
      <NavBar isDark inDrawer onDrawerClose={ closeMobileMenu }/>
      <div className={ styles.linksContainer }>
        <div className={ styles.topSpacer }/>
        <div className={ styles.links }>
          <Link to='/' className={ styles.link }
                activeClassName={ styles.active }>Home</Link>
          <Link to='/work' className={ styles.link }
                activeClassName={ styles.active }>Work</Link>
          <Link to='/about' className={ styles.link }
                activeClassName={ styles.active }>About</Link>
          <Link to='/careers' className={ styles.link }
                activeClassName={ styles.active }>Careers</Link>
        </div>
        <p onClick={ handleOpenQuote } className={ styles.quote }>Request a
          quote</p>
        <FSDialog open={ openQuote } handleClose={ handleCloseQuote }>
          <ContactForm onSubmit={ handleCloseQuote }/>
        </FSDialog>
      </div>
    </div>
  )
}

export default MobileMenu

import React from 'react'
import { useField } from 'formik'
import { FilledInput, FormControl, InputLabel } from '@material-ui/core'
import styles from '../../styles/ContactFormMessage.module.css'
import FormHelperText from '@material-ui/core/FormHelperText'
import classNames from 'classnames'

export interface Props {
  fieldName: string;
  onChange?: (e: any) => void;
  light?: boolean;
  placeholder?: string;
}

const ContactFormMessage = ({fieldName, onChange, light, placeholder}: Props): JSX.Element => {
  const [ field, {touched, error} ] = useField(fieldName)

  return (
    <div className={classNames(styles.container, light && styles.light)}>
      <InputLabel required={Boolean(placeholder)} className={styles.messageLabel}>{placeholder}</InputLabel>
      <FormControl fullWidth required error={Boolean(touched && error)}>
          <FilledInput {...field} multiline name={fieldName} className={styles.textField} rows={8} disableUnderline fullWidth error={Boolean(touched && error)} onInput={onChange} />
          <FormHelperText>{touched && error}</FormHelperText>
      </FormControl>
    </div>
  )
}

export default ContactFormMessage

import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export interface Props {
  circleColor?: string;
  checkColor?: string;
  className?: string;
}

const CheckMarkIcon = ({circleColor = 'white', checkColor = '#FF871C', className}: Props): JSX.Element => {
  return (
    <SvgIcon width="86" height="86" viewBox="0 0 86 86" fill="none" className={className}>
      <line x1="21.7071" y1="51.2929" x2="33.7071" y2="63.2929" stroke={circleColor} strokeWidth="2"/>
      <line x1="69.7167" y1="27.6974" x2="33.7167" y2="64.6974" stroke={circleColor} strokeWidth="2"/>
      <circle cx="43" cy="43" r="42.25" stroke={checkColor} strokeWidth="1.5" fill='none'/>
    </SvgIcon>
  )
}

export default CheckMarkIcon

import React, { useState } from 'react'
import { Formik, Form, FormikHelpers } from 'formik'
import styles from '../../styles/ContactForm.module.css'
import ContactFormMessage from './ContactFormMessage'
import classNames from 'classnames'
import ArrowButton from '../ArrowButton'
import 'whatwg-fetch'
import FSDialog from '../FSDialog'
import CheckMarkIcon from '../svg/CheckMarkIcon'
import * as Yup from 'yup'
import SectionHeader from '../../components/SectionHeader'
import { ContactFormSubmitterDetails } from './ConfactFormSubmitterDetails'

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Please enter a valid email address'),
  subject: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
})

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  subject: '',
  message: '',
}

interface ContactValues {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
}

interface Props {
  onSubmit?: () => void;
}

const ContactForm = ({ onSubmit }: Props): JSX.Element => {
  const [ open, setOpen ] = useState(false)

  const submit = async (values: ContactValues, { resetForm }: FormikHelpers<ContactValues>) => {
    const data = new FormData()
    Object.keys(values).forEach((key) => {
      data.append(key, values[key as keyof ContactValues])
    })
    // @ts-ignore
    const res = await window.fetch('https://getform.io/f/7ecaaf87-590e-43f0-a2e8-5da97b8ee619', {
      method: 'post',
      body: data,
      dataType: 'json',
      headers: {
        "Accept": "application/json"
      },
      mode: 'cors',
    })
    if (res.status === 200) {
      resetForm()
      setOpen(true)
    } else {
      // error handling?
    }
  }

  const handleThanksClose = () => {
    setOpen(false)
    onSubmit && onSubmit()
  }

  return (
    <section className={ classNames(styles.section, styles.projectSection) }>
      <SectionHeader className={ styles.header }>Got a project?</SectionHeader>
      <p className={ styles.projectTagLine }>We love meeting new people and
        building new things. Let’s chat.</p>
      <Formik initialValues={ initialValues } onSubmit={ submit }
              validationSchema={ validationSchema }>
        {
          ({ handleSubmit }) => (
            <Form className={ styles.form }>
              <ContactFormSubmitterDetails lastField="subject" lastFieldLabel="Subject" lastFieldRequired />
              <ContactFormMessage fieldName="message" placeholder="Message"/>
              <div className={ styles.ctaContainer }>
                <ArrowButton dark handleClick={ handleSubmit }>Send
                  it</ArrowButton>
              </div>
            </Form>
          )
        }
      </Formik>
      <FSDialog open={ open } handleClose={ handleThanksClose }
                withClose={ false } closeOnClick>
        <div className={ styles.thanksContainer }>
          <h2 className={ styles.thanksHeader }>Thanks for reaching out!</h2>
          <p className={ styles.thanksBody }>We’ll get back to you when we’ve
            found our computer.</p>
          <CheckMarkIcon className={ styles.check }/>
        </div>
      </FSDialog>
    </section>
  )
}

export default ContactForm

import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import styles from '../styles/FSDialog.module.css'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import classNames from 'classnames'


export interface Props {
  open: boolean;
  handleClose: () => void;
  children: JSX.Element | JSX.Element[];
  withClose?: boolean;
  closeOnClick?: boolean;
  fullScreen?: boolean;
  contentClassName?: string;
  closeButtonClassName?: string;
}

const FSDialog = ({ closeButtonClassName, contentClassName, open, handleClose, children, withClose = true, closeOnClick = false, fullScreen }: Props): JSX.Element => {
  return (
    <Dialog open={ open } onClose={ handleClose } fullWidth maxWidth='xl'
            fullScreen={ fullScreen }
            onClick={ () => {
              closeOnClick && handleClose()
            } }>
      <DialogContent
        className={ classNames(styles.dialogContent, contentClassName) }>
        { withClose &&
        <Button
          className={ classNames(styles.closeDialog, closeButtonClassName) }
          onClick={ handleClose }
          disableRipple>Close X</Button> }
        { children }
      </DialogContent>
    </Dialog>
  )
}

export default FSDialog

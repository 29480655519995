import React from 'react'
import { Flex, Text, Image } from 'rebass'
import styles from '../styles/NavBar.module.css'
import { Link } from 'gatsby'
import classNames from 'classnames'
import Hidden from '@material-ui/core/Hidden'
import MobileMenuButton from './MobileMenuButton'
import CloseIcon from './svg/CloseIcon'
import { IconButton } from '@material-ui/core'

interface Props {
  isDark?: boolean;
  inDrawer?: boolean;
  onDrawerClose?: () => void
}

const NavBar = ({isDark = false, inDrawer = false, onDrawerClose}: Props) => {
  const linkClass = classNames(styles.link, isDark && styles.darkLink )
  const activeLinkClass = classNames(styles.active, isDark && styles.darkActive )

  return (
    <div className={ styles.container }
         style={ { backgroundColor: isDark ? '#1E1E1E' : 'white' } }>
      <Flex
        className={ styles.linksContainer }
        alignItems='center'
        justifyContent='space-between'
      >
        <Link to='/' className={ styles.homeLink }>
          <Text className={ styles.leftLogo }
                style={ { color: isDark ? 'white' : 'black' } }>FS
            WOODWORKS</Text>
        </Link>
        <Link activeClassName={ activeLinkClass } className={ linkClass } to='/'
              color='black'>
          HOME
        </Link>
        <Link activeClassName={ activeLinkClass } className={ linkClass }
              to='/work' color='black'>
          WORK
        </Link>
        <Link activeClassName={ activeLinkClass } className={ linkClass }
              to='/about' color='black'>
          ABOUT
        </Link>
        <Link activeClassName={ activeLinkClass } className={ linkClass }
              to='/careers' color='black'>
          CAREERS
        </Link>
        <Image src='/images/logo.png' className={ styles.logo }/>
      </Flex>
      <div className={ styles.mobileContainer }>
        <Image src='/images/logo.png' className={ styles.logo }/>
        <div className={ styles.buttonContainer }>
          {
            inDrawer ?
              <IconButton onClick={ onDrawerClose }
                          className={ styles.closeButton }>
                <CloseIcon size={ 30 }/>
              </IconButton> :
              <MobileMenuButton dark={ isDark }/>
          }
        </div>
      </div>
    </div>
  )
}

export default NavBar

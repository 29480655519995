import React from 'react'
import { Flex, Image, Heading, Text, Link } from 'rebass'
import styles from '../styles/Footer.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import { blocksToText } from '../helpers'
import { SanitySocial } from '../../graphql-types'
import classNames from 'classnames'
import BackToTopIcon from './svg/BackToTopIcon'
import { Hidden } from '@material-ui/core'

const BlockContent = require('@sanity/block-content-to-react')

const query = graphql`
  query footer {
    address: sanityCopy(name: {eq: "address"}) {
      _rawText(resolveReferences: {maxDepth: 2})
    }
    phone: sanityCopy(name: {eq: "phone"}) {
      _rawText(resolveReferences: {maxDepth: 2})
    }
    email: sanityCopy(name: {eq: "email"}) {
      _rawText(resolveReferences: {maxDepth: 2})
    }
    allSanitySocial {
      nodes {
        id
        title
        url
        image {
          asset {
            url
          }
        }
      }
    } 
  }
`

const Footer = (): JSX.Element => {
  const { address, phone, email, allSanitySocial: { nodes: social } } = useStaticQuery(query)

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return (
    <Flex className={ styles.container } dir='row'>
      <section className={ styles.section }>
        <Image
          src='/images/footer/sublogo.png'
          className={ styles.logo }
        />
      </section>
      <section className={ styles.section }>
        <Heading className={ styles.sectionHeader }>ADDRESS</Heading>
        <BlockContent className={ styles.sectionText }
                      blocks={ address._rawText }/>
      </section>
      <section className={styles.section}>
        <Heading className={styles.sectionHeader}>CONTACT</Heading>
        <Text className={styles.sectionText}><span className={styles.contactSign}>E </span>{blocksToText(email._rawText)}</Text>
        <Text className={styles.sectionText}><span className={styles.contactSign}>P </span>{blocksToText(phone._rawText)}</Text>
      </section>
      <section className={classNames(styles.section, styles.socialSection)}>
        <Heading className={styles.sectionHeader}>SOCIAL</Heading>
        <div>
          {
            social.map(({ id, url, title, image }: SanitySocial) => (
              <Link key={ id } href={ url || '' }
                    className={ styles.socialLink }>
                <Image src={ image?.asset?.url || '' } alt={ title || '' }
                       className={ styles.social }/>
              </Link>
            ))
          }
        </div>
      </section>
      <Hidden smUp implementation='css'>
        <div className={ styles.backToTop } onClick={ scrollToTop }>
          <BackToTopIcon/>
        </div>
      </Hidden>
    </Flex>
  )
}

export default Footer

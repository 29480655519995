import React, { SyntheticEvent } from 'react'
import styles from '../styles/ArrowButton.module.css'
import { Button } from '@material-ui/core'
import classNames from 'classnames'
import { Link } from 'gatsby'
import CTAArrow from './svg/CTAArrow'

export interface Props {
  children: string;
  dark?: boolean;
  handleClick?: (e: SyntheticEvent) => void;
  to?: string;
}

const ArrowButton = ({
                       to, children, dark = false, handleClick = () => {
  },
                     }: Props): JSX.Element => {
  const button = (
    <div className={ styles.buttonContainer }>
      <Button className={ styles.button }
              classes={ { label: classNames(styles.buttonLabel, dark && styles.buttonLabelDark) } }
              disableRipple onClick={ handleClick }>
        { children }
      </Button>
      <CTAArrow className={ classNames(styles.arrow, dark && styles.darkArrow) }
                color={ dark ? 'white' : 'black' }/>
    </div>
  )

  if (!to) return button

  return (
    <Link className={styles.link} to={to}>
      {button}
    </Link>
  )
}

export default ArrowButton

import React from 'react'
import { Helmet } from 'react-helmet'
import NavBar from './NavBar'
import Footer from './Footer'
import '../styles/PageLayout.css'
import favicon from '../../public/images/favicon.png'

export interface Props {
  title: string;
  children: JSX.Element | JSX.Element[];
  darkNavBar?: boolean;
}

const PageLayout = ({title, children, darkNavBar = false}: Props): JSX.Element => {
  return (
    <main>
      <Helmet>
        <link rel="icon" href={favicon}/>
        <title>{title}</title>
      </Helmet>
      <NavBar isDark={darkNavBar} />
      {children}
      <Footer />
    </main>
  )
}

export default PageLayout

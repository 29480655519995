import { Grid } from "@material-ui/core"
import React from "react"
import ContactFormField from "./ContactFormField"
import styles from '../../styles/ContactForm.module.css'

export interface Props {
  lastField: string;
  lastFieldLabel: string;
  lastFieldRequired: boolean;
  dark?: boolean;
}

export const ContactFormSubmitterDetails = ({lastField, lastFieldRequired, lastFieldLabel, dark}: Props): JSX.Element => {
  return (<Grid container spacing={ 2 } className={ styles.grid }>
    <Grid item className={ styles.item } xs={ 12 } sm={ 6 }>
      <ContactFormField dark={dark} name='firstName' label='First Name'/>
    </Grid>
    <Grid item className={ styles.item } xs={ 12 } sm={ 6 }>
      <ContactFormField dark={dark} name='lastName' label='Last Name'/>
    </Grid>
    <Grid item className={ styles.item } xs={ 12 } sm={ 6 }>
      <ContactFormField dark={dark} name='email' label='Email Address'/>
    </Grid>
    <Grid item className={ styles.item } xs={ 12 } sm={ 6 }>
      <ContactFormField dark={dark} required={lastFieldRequired} name={lastField} label={lastFieldLabel} />
    </Grid>
  </Grid>)
}
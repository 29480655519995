import React from 'react'
import styles from '../styles/SectionHeader.module.css'
import classNames from 'classnames'

export interface Props {
  children: string;
  dark?: boolean;
  center?: boolean;
  className?: string;
}

const SectionHeader = ({children, dark = false, center = false, className}: Props): JSX.Element => {
  return (
    <h2 className={classNames(styles.header, dark && styles.dark, center && styles.center, className)}>{children}</h2>
  )
}

export default SectionHeader

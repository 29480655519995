import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export interface Props {
  circleColor?: string;
  arrowColor?: string;
  className?: string;
}

const BackToTopIcon = ({ circleColor = '#FF871C', arrowColor = 'black', className }: Props): JSX.Element => {
  return (
    <SvgIcon width="12" height="23" viewBox="0 0 12 23" fill="none"
             className={ className }>

      <path
        d="M6.53033 0.46967C6.23744 0.176777 5.76256 0.176777 5.46967 0.46967L0.696698 5.24264C0.403805 5.53553 0.403805 6.01041 0.696698 6.3033C0.989592 6.59619 1.46447 6.59619 1.75736 6.3033L6 2.06066L10.2426 6.3033C10.5355 6.59619 11.0104 6.59619 11.3033 6.3033C11.5962 6.01041 11.5962 5.53553 11.3033 5.24264L6.53033 0.46967ZM6.75 23L6.75 1L5.25 1L5.25 23L6.75 23Z"
        fill="white"/>

    </SvgIcon>
  )
}

export default BackToTopIcon

import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export interface Props {
  color?: string;
  size?: number;
}

const CloseIcon = ({color = 'white', size = 30}: Props): JSX.Element => {
  return (
    <SvgIcon width="45" height="45" viewBox="0 0 45 45" fill="none" style={{width: size, height: size}}>
    <path id="icon" fillRule="evenodd" clipRule="evenodd" d="M22.5002 26.4777L10.4265 38.5514L6.44897 34.574L18.5227 22.5002L6.44897 10.4265L10.4265 6.44897L22.5002 18.5227L34.574 6.44897L38.5514 10.4265L26.4777 22.5002L38.5514 34.574L34.574 38.5514L22.5002 26.4777Z" fill={color}/>
    </SvgIcon>
  )
}

export default CloseIcon

import React from 'react'
import { useField } from 'formik'
import { FormControl, InputLabel } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import styles from '../../styles/ContactFormField.module.css'
import classNames from 'classnames'
import { Box } from 'rebass'
import FormHelperText from '@material-ui/core/FormHelperText'

export interface Props {
  name: string;
  label: string;
  required?: boolean;
  dark?: boolean;
}

const ContactFormField = ({name, label, required = true, dark}: Props): JSX.Element => {
  const [ field, {error, touched} ] = useField(name)

  return (
    <Box p={2} className={styles.container}>
      <FormControl required={required} fullWidth className={classNames(styles.formControl, dark && styles.dark)} error={Boolean(touched && error)}>
        <InputLabel htmlFor={name} classes={{root: styles.text, shrink: styles.labelShrink, error: styles.labelError}}>{label}</InputLabel>
        <Input id={name} {...field} classes={{root: classNames(styles.input, styles.text), input: classNames(styles.input, styles.inputText)}} />
        <FormHelperText>{touched && error}</FormHelperText>
      </FormControl>
    </Box>
  )
}

export default ContactFormField

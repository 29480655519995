import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export interface Props {
  color?: string;
  size?: number;
  className?: string;
}

const CTAArrow = ({ color = 'black', size = 40, className }: Props): JSX.Element => {
  return (
    <SvgIcon width="93.12" height="17.85" viewBox="0 0 93.12 17.85" fill="none"
             style={ { width: size, height: size } } className={ className }>
      <path
        d="M84,.07,82.88,1.16a.22.22,0,0,0,0,.32L89,7.58a.23.23,0,0,1-.16.39H.23A.22.22,0,0,0,0,8.19V9.74A.23.23,0,0,0,.23,10H88.86a.22.22,0,0,1,.16.38l-6,6a.23.23,0,0,0,0,.31l1.1,1.1a.22.22,0,0,0,.32,0l8.63-8.63a.22.22,0,0,0,0-.32L84.29.07A.22.22,0,0,0,84,.07Z"
        stroke={ color }/>
    </SvgIcon>
  )
}

export default CTAArrow

import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export interface Props {
  className?: string;
  color?: string;
}

const MobileMenuButtonIcon = ({className, color = '#191C17'}: Props): JSX.Element => {
  return (
    <SvgIcon width="22" height="18" viewBox="0 0 22 18" fill="none" className={className}>
      <line y1="1.25" x2="22" y2="1.25" stroke={color} strokeWidth="1.5"/>
      <line y1="9.25" x2="22" y2="9.25" stroke={color} strokeWidth="1.5"/>
      <line y1="17.25" x2="22" y2="17.25" stroke={color} strokeWidth="1.5"/>
    </SvgIcon>
  )
}

export default MobileMenuButtonIcon
